// extracted by mini-css-extract-plugin
export var subsection = "dev-module--subsection--cc920";
export var devPage = "dev-module--devPage--02e6b";
export var container = "dev-module--container--6d65a";
export var nav = "dev-module--nav--50078";
export var nav_ul = "dev-module--nav_ul--a05af";
export var nav_li = "dev-module--nav_li--cd909";
export var nav_link = "dev-module--nav_link--53fac";
export var nav_link___yellow = "dev-module--nav_link___yellow--583d6";
export var intro = "dev-module--intro--30023";
export var intro_header_1 = "dev-module--intro_header_1--17386";
export var fromLeft = "dev-module--fromLeft--4cf96";
export var intro_description = "dev-module--intro_description--f10d7";
export var fadeIn = "dev-module--fadeIn--01468";
export var lesson = "dev-module--lesson--6a5c1";
export var lesson_header_2 = "dev-module--lesson_header_2--d1fef";
export var lesson_header_3 = "dev-module--lesson_header_3--3d4d1";
export var lesson_description = "dev-module--lesson_description--96a48";
export var lesson_indent = "dev-module--lesson_indent--dfea7";
export var contact_header_3 = "dev-module--contact_header_3--d4f65";
export var contact_ul = "dev-module--contact_ul--93412";
export var contact_li = "dev-module--contact_li--53749";
export var contact_a = "dev-module--contact_a--6e3c0";
export var contact_svg = "dev-module--contact_svg--13cb4";
export var contact_line = "dev-module--contact_line--fb089";
export var container___footer = "dev-module--container___footer--a74bc";
export var footer_copyright = "dev-module--footer_copyright--519c0";
export var footer_author = "dev-module--footer_author--4d1f0";
export var pulsate = "dev-module--pulsate--2e328";